import React, { useEffect, ReactNode } from 'react';
import S from './styles';
import { useDocument } from '../../hooks/useDocument';
import { useAuth0Token } from '../../hooks/useAuth0Token';
import { ArticleType } from '../../../../types/articleType';
import { Content } from './Content';

interface DialogArticleProps {
  slug: string;
  children?: ReactNode[] | ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const DialogArticle = ({ slug, children, isOpen, setIsOpen }: DialogArticleProps) => {
  const { simpleToken } = useAuth0Token();
  const { meta, data } = useDocument(slug, simpleToken);

  useEffect(() => {
    if (isOpen) {
      // Set focus inside Dialog to enable keyboard scrolling
      const element = document.getElementById('dialog');
      if (element) {
        const inner = element.querySelector('div');
        (inner as HTMLElement).tabIndex = -1;
        (inner as HTMLElement).focus();
        (inner as HTMLElement).style.outline = '0';
      }
      // Change stack context to show dialog in front
      const appWrapper = document.getElementById('appWrapper');
      if (appWrapper) {
        appWrapper.style.position = 'relative';
        appWrapper.style.zIndex = '26';
      }
    }
    return () => {
      const appWrapper = document.getElementById('appWrapper');
      if (appWrapper) {
        appWrapper.style.position = 'static';
        appWrapper.style.zIndex = '0';
      }
    };
  }, [isOpen]);

  return (
    <>
      {children}
      <S.StyledDialog isOpen={isOpen} handleClose={() => setIsOpen(false)}>
        <Content data={data as ArticleType} isUpdating={meta.isUpdating} />
      </S.StyledDialog>
    </>
  );
};
