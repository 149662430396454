import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { Button } from '@naf/button';
import { Loader } from '@naf/loader';
import { nafColor } from '@nafcore/theme';
import { Input } from '@naf/input';
import { ButtonLink } from '@naf/button-link';
import { Link } from 'react-router-dom';
import { Label } from '@naf/label';
import { CheckCircle } from '@styled-icons/material/CheckCircle';
import { ProductType, useProductChangeCalculator } from '../../../../../hooks/useProductChangeCalculator';
import { useSessionStorage } from '../../../../../hooks/useSessionStorage';
import { WindshieldWiperChangeCalculationType } from '../../../../../../../types/windshieldChangeCalculationTypes';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import useSelector from '../../../../../redux/typedHooks';

export const WindShieldWiperChangeCalculatorBlock = () => {
  const [centerId] = useSessionStorage('centerId', undefined);
  const [licensePlateNumber, setLicensePlateNumber] = useState<string>('');
  const [calculationResult, setCalculationResult] = useState<WindshieldWiperChangeCalculationType | undefined>();
  const { calculate, calculation, isLoading, error } = useProductChangeCalculator({
    productType: ProductType.WINDSHIELD,
    licensePlateNumber,
    centerId: centerId as string | undefined,
  });

  const isMember = useSelector((state) => state?.myMembership?.customerInformation?.data?.membership?.isValid ?? false);

  useEffect(() => {
    setCalculationResult(calculation as WindshieldWiperChangeCalculationType);
  }, [calculation]);

  const formatPrice = (price) => (Number.isInteger(price) ? price.toFixed(0) : price.toFixed(2));

  const getPriceComponents = () => {
    if (!calculationResult) return null;

    const isCampaign = calculationResult.memberPrice?.isCampaign || calculationResult.nonMemberPrice?.isCampaign;
    const memberPrice = calculationResult.memberPrice?.isCampaign
      ? calculationResult.memberPrice?.campaignPrice ?? 0
      : calculationResult.memberPrice?.price ?? 0;
    const nonMemberPrice = calculationResult.nonMemberPrice?.isCampaign
      ? calculationResult.nonMemberPrice?.campaignPrice ?? 0
      : calculationResult.nonMemberPrice?.price ?? 0;

    if (memberPrice === nonMemberPrice || memberPrice > nonMemberPrice) {
      return (
        <Text variant={TextVariant.Header3}>Pris for vindusviskerskift foran: kr {formatPrice(nonMemberPrice)},-</Text>
      );
    }

    return (
      <>
        <Text variant={TextVariant.Header3}>
          {isCampaign ? (
            <Row>
              {`${isMember ? 'Din pris:' : 'Medlemspris:'} kr ${formatPrice(memberPrice)},-`}
              <StruckText variant={TextVariant.Header3}>
                {formatPrice(calculationResult.memberPrice.price)},-
              </StruckText>
            </Row>
          ) : (
            `${isMember ? 'Din pris:' : 'Medlemspris:'} kr ${formatPrice(memberPrice)},-`
          )}
        </Text>
        <Text variant={TextVariant.Header3}>Pris for ikke-medlemmer: kr {formatPrice(nonMemberPrice)},-</Text>
      </>
    );
  };

  return (
    <WindshieldWiperChange>
      {(calculationResult?.memberPrice?.isCampaign || calculationResult?.nonMemberPrice?.isCampaign) && (
        <StyledLabel variant="moss" text="Kampanje" />
      )}
      <Text variant={TextVariant.Header2}>Se hvor mye vindusviskerskift på din bil koster</Text>
      <Text variant={TextVariant.ArticleTextLarge}>
        Legg inn registeringsnummer for å beregne hvor mye det vil koste å skifte vindusviskere for din bil.
      </Text>
      <Search>
        <Input
          placeholder="DN12345"
          id="registrationNumber"
          type="text"
          onChange={(number: string) => setLicensePlateNumber(number)}
          onKeyUp={(e: KeyboardEvent) => {
            if (e.key === 'Enter') calculate();
          }}
        />
        <Button type="button" variant="secondary" onClick={() => calculate()}>
          Beregn pris
        </Button>
      </Search>
      {isLoading && (
        <SpinnerWrapper>
          <Loader />
        </SpinnerWrapper>
      )}
      {error && !isLoading && (
        <LookupResultError>
          <Text variant={TextVariant.Header3}>Vi klarte ikke finne en bil på dette registreringsnummeret.</Text>
          <Text>Ta kontakt med kundeservice for en prisforespørsel på vindusviskerskift for ditt kjøretøy.</Text>
          <ButtonLink as={Link} to="/kundeservice" text="Kontakt kundeservice" />
        </LookupResultError>
      )}
      {!error && !isLoading && calculationResult && (
        <LookupResultSuccess>
          <VehicleText>
            <CheckCircle size={22} />
            {calculationResult.vehicle && (
              <SuccessText variant={TextVariant.BodyText}>
                {calculationResult.vehicle?.manufactureName} {calculationResult.vehicle?.modelName}{' '}
                {calculationResult.vehicle?.year}
              </SuccessText>
            )}
          </VehicleText>
          {calculationResult.memberPrice?.price && calculationResult.nonMemberPrice?.price && (
            <Summary>{getPriceComponents()}</Summary>
          )}
          {(!calculationResult.memberPrice?.price || !calculationResult.nonMemberPrice?.price) && (
            <>
              <Text variant={TextVariant.Header3}>Vi klarer ikke å kalkulere riktig pris for din bil</Text>
              <Text>Ta kontakt med kundeservice for en prisforespørsel på vindusviskerskift for ditt kjøretøy</Text>
              <ButtonLink as={Link} to="/kundeservice" text="Kontakt kundeservice" />
            </>
          )}
          {calculationResult.link && calculationResult.link.data && (
            <Button
              href={calculationResult.link.data.href}
              target={calculationResult.link.data.blank ? '_blank' : '_self'}
            >
              Finn ledig tid
            </Button>
          )}
        </LookupResultSuccess>
      )}
    </WindshieldWiperChange>
  );
};

export const WindShieldWiperChangeCalculatorSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return () => (
      <ArticleBlockContentGridCol>
        <WindShieldWiperChangeCalculatorBlock />
      </ArticleBlockContentGridCol>
    );
  }
  return () => <WindShieldWiperChangeCalculatorBlock />;
};

const StyledLabel = styled(Label)`
  margin-bottom: ${spacing.space24};
  margin-top: -${spacing.space24};
  display: flex;
  width: min-content;

  @media (max-width: ${breakpoints.s}) {
    margin-top: ${spacing.space8};
  }
`;

const Row = styled.div<{ maxWidth?: number }>`
  display: flex;
  flex-direction: row;
  gap: ${spacing.space20};
`;

const StruckText = styled(Text)`
  margin-left: ${spacing.space20};
  text-decoration: line-through;
`;

const WindshieldWiperChange = styled.div`
  margin-bottom: ${spacing.space12};

  @media (max-width: ${breakpoints.s}) {
    margin-bottom: 0;
  }

  h2 {
    margin: 0 0 ${spacing.space8} !important;
  }
  p {
    margin: 0 !important;
  }
`;

const SuccessText = styled(Text)`
  display: inline-flex;
  color: ${nafColor.information.successDark};
  margin-left: ${spacing.space8} !important;
`;

const Search = styled.div`
  display: flex;
  margin-top: ${spacing.space32};

  button {
    margin-left: ${spacing.space16};
  }

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space20};
  }

  @media (max-width: ${breakpoints.s}) {
    margin-top: ${spacing.space16};
    button {
      margin-left: ${spacing.space8};
    }
    > div {
      flex-grow: 1;
    }
  }
`;

const LookupResultSuccess = styled.div`
  margin-top: ${spacing.space8};

  svg {
    color: ${nafColor.information.successDark};
  }
  a {
    margin-top: ${spacing.space16};
  }
`;

const LookupResultError = styled.div`
  margin-top: ${spacing.space40};

  h3 {
    margin: 0 0 ${spacing.space8};
  }
  p {
    margin: 0;
  }
  a {
    margin-top: ${spacing.space16};
  }

  @media (max-width: ${breakpoints.s}) {
    margin-top: ${spacing.space32};
  }
`;

const VehicleText = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacing.space20};

  > *:first-child {
    margin-right: ${spacing.space4};
  }
`;

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${spacing.space24} 0;
  max-width: 512px;

  h3 {
    margin: 0 0 ${spacing.space8};
  }

  h4,
  p {
    margin: 0;
  }

  @media (max-width: ${breakpoints.s}) {
    margin: ${spacing.space20} 0;
    max-width: 100%;
  }
`;

const SpinnerWrapper = styled.div`
  margin-top: ${spacing.space24};
  width: fit-content;

  @media (max-width: ${breakpoints.s}) {
    margin-left: auto;
    margin-right: auto;
  }
`;
