import React, { ReactNode } from 'react';
import { Text, TextVariant } from '@naf/text';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

const ArticleWrapper = ({
  children,
  styleName,
  isArticle,
}: {
  children?: ReactNode;
  styleName: string;
  isArticle: boolean;
}) =>
  isArticle ? (
    <ArticleBlockContentGridCol className={`article_${styleName}`}>{children}</ArticleBlockContentGridCol>
  ) : (
    children
  );

export const block = (context: 'article' | 'stripped' = 'stripped') => {
  const isArticle = context === 'article';
  return {
    ingress: ({ children }: { children?: ReactNode; _key?: string }) => (
      <ArticleWrapper isArticle={isArticle} styleName="ingress">
        <Text variant={TextVariant.Ingress}>{children}</Text>
      </ArticleWrapper>
    ),
    h1: ({ children }: { children?: ReactNode; _key?: string }) => (
      <ArticleWrapper isArticle={isArticle} styleName="h1">
        <Text variant={TextVariant.Header1}>{children}</Text>
      </ArticleWrapper>
    ),
    h2: ({ children, _key }: { children?: ReactNode; _key?: string }) => {
      const headingId = children ? `${children.toString().replace(/ /g, '_').trim().toLowerCase()}_${_key}` : undefined;
      return (
        <ArticleWrapper isArticle={isArticle} styleName="h2">
          <Text id={headingId} variant={TextVariant.Header2}>
            {children}
          </Text>
        </ArticleWrapper>
      );
    },
    h3: ({ children }: { children?: ReactNode; _key?: string }) => (
      <ArticleWrapper isArticle={isArticle} styleName="h3">
        <Text variant={TextVariant.Header3}>{children}</Text>
      </ArticleWrapper>
    ),
    normal: ({ children }: { children?: ReactNode; _key?: string }) => (
      <ArticleWrapper isArticle={isArticle} styleName="normal">
        <div className="paragraph-content" style={{ margin: '1em 0' }}>
          {children}
        </div>
      </ArticleWrapper>
    ),
    blockquote: ({ children }: { children?: ReactNode; _key?: string }) => (
      <ArticleWrapper isArticle={isArticle} styleName="blockquote">
        <blockquote>{children}</blockquote>
      </ArticleWrapper>
    ),
    h4: ({ children }: { children?: ReactNode; _key?: string }) => (
      <ArticleWrapper isArticle={isArticle} styleName="h4">
        <h4>{children}</h4>
      </ArticleWrapper>
    ),
    h5: ({ children }: { children?: ReactNode; _key?: string }) => (
      <ArticleWrapper isArticle={isArticle} styleName="h5">
        <h5>{children}</h5>
      </ArticleWrapper>
    ),
    h6: ({ children }: { children?: ReactNode; _key?: string }) => (
      <ArticleWrapper isArticle={isArticle} styleName="h6">
        <h6>{children}</h6>
      </ArticleWrapper>
    ),
  };
};
